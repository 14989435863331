.cameras-list-body {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: fit-content;
	margin-left: 10px;
	margin-top: 10px;
	background-color: white;
	width: 100%;
}

.detailed-cameras-list-body {
	display: flex;
	flex-direction: row;
	width: 100%;
	overflow: hidden;
}

.detailed-cameras-list-left {
	display: flex;
	flex-direction: column;
	max-width: 30%;
	height: 100%;
	overflow: auto;
	background-color: var(--localLightBlueBg);
}

.detailed-cameras-list-card-container {
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 7px;
	padding-bottom: 7px;
}

.detailed-cameras-list-right {
	background-color: white;
	width: 100%;
	height: 100%;
	padding-left: 20px;
	padding-right: 20px;
}

.cameras-list-card-container {
	max-width: 33%;
}

.detailed-cameras-list-no-selection-container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.detailed-cameras-list-tables-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.detailed-cameras-list-table-wrapper {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	padding-top: 10px;
	max-height: 95%;
}

.detailed-cameras-list-table-wrapper-no-admin {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	padding-top: 10px;
	height: 100%;
}

.trigger-image {
	object-fit: contain;
	overflow: hidden;
	height: 50px;
}

.trigger-image-container {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.centered-container {
	display: flex;
	align-items: center;
	justify-content: center;
}
