.history-page-graph-document {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 25px;
	gap: 20px;
}

.history-page-graph-line-chart-row-wrapper {
	width: 100%;
	height: 55%;
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.history-page-graph-line-chart-container {
	width: 75%;
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: #fefefe;
	gap: 20px;
	border-radius: 6px;
}

.history-page-graph-line-chart-title-row {
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.history-page-graph-line-chart-filter-row {
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 5%;
}

.history-page-graph-line-chart-filter-container {
	min-width: fit-content;
	width: 15%;
}

.history-page-graph-line-chart {
	height: 100%;
	width: 100%;
	padding-right: 5%;
}

.history-page-graph-line-chart-row-right-side-wrapper {
	height: 100%;
	width: 25%;
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.history-page-graph-line-chart-row-right-side-approval-wrapper {
	height: 50%;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: #fefefe;
	border-radius: 6px;
	padding-left: 15px;
	padding-right: 15px;
}

.history-page-graph-line-chart-row-progress-arc-wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 5px;
}

.history-page-graph-second-chart-row-wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.history-page-graph-second-chart-wrapper {
	width: 45%;
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: #fefefe;
	gap: 20px;
	border-radius: 6px;
}

.history-page-graph-second-chart-coverage-wrapper {
	width: 55%;
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: #fefefe;
	gap: 20px;
	border-radius: 6px;
}

.history-page-graph-line-chart-title-coverage-spinner-wrapper {
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}

.history-page-graph-title-txt {
	font-family: "Mollen";
	font-size: x-large;
	color: #213a44;
}

.history-page-graph-subtitle-txt {
	font-family: "Mollen";
	font-size: large;
	color: #213a44;
}

.recharts-wrapper text {
	font-family: "Mollen";
	fill: #213a44;
}
