/* Initial state of the entering element */
.fade-enter {
    opacity: 0;
}

/* Final state of the entering element */
.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

/* Initial state of the exiting element */
.fade-exit {
    opacity: 1;
}

/* Final state of the exiting element */
.fade-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
}

.stream-alert-txt {
    font-family: "Mollen";
    font-size: large;
    color: var(--localGrey);
}
